import React, { useState, useCallback, useEffect, useRef } from "react";
import { Routes } from "../../../configs";
import { useHistory } from "react-router-dom";
import useModalWithKeyboard from "../../../hooks/useModalWithKeyboard";
import { uploadIdentificationImage } from "../../../modules/ApiHelpers";

import APIService from "../../../modules/apiService";
import AuthService from "../../../modules/authService";
import Helpers from "../../../modules/helpers";
import {
  EVENT_RELOAD_PRESCRIPTION_PROFILES,
  EVENT_SHOW_MODAL_INFO,
  EVENT_RELOAD_MEDICAL_LICENSE,
} from "../../../constants/Events";
import locales_es from "../../../locales/es";
import {
  validateEmptyArrays,
  validateEmptyFields,
} from "../../../utils/validation/forms";
import { EventRegister } from "../../../modules/EventRegister";
import { USER_STATUS_APPROVED } from "../../../models/constants";
import { showModalInfo } from "../../../modules/ViewHelpers";
import CountryService from "../../../modules/CountryService";
import ListItem from "../../../components/ListItem";
import InputApp from "../../../components/InputApp";
import TranslationService from "../../../locales";
import { useTheme } from "../../../configs/Theme";
import PrescriptionService from "../../../modules/PrescriptionService";
import { set } from "react-native-reanimated";

let nativeEventTestSearchSpecialties = "";

const useWorkProfilePage = () => {
  const history = useHistory();
  const params = history.location.state;

  const prescriptionService = new PrescriptionService();
  const prescriptionIdFromStorage = prescriptionService.get();

  let {
    prescriptionProfileId,
    stepTitle,
    firstProfile,
    hasSignup,
    stepNumber,
  }: any = params ? params : null;
  prescriptionProfileId = prescriptionProfileId
    ? prescriptionProfileId
    : prescriptionProfileId;

  const api = new APIService();
  const auth = new AuthService();
  const translationService = new TranslationService();
  const helpers = new Helpers();
  const [hiddenText, setHiddenText] = useState<any>(true);
  const [load, setLoad] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingText, setLoadingText] = useState<any>("");
  const [errors, setErrors] = useState<any>([]);
  const { theme }: any = useTheme();
  const [translations, setTranslations] = useState<any>(null);
  const [userData, setUserData] = useState<any>({});
  // const [countryId, setCountryId] = useState('');
  // const [prescriptionProfileId, setPrescriptionProfileId] = useState(null);
  const [identificationImage, setIdentificationImage] = useState<any>(null);
  const [searchSpecialties, setSearchSpecialties] = useState<any>("");
  const [searchSpecialtiesResults, setSearchSpecialtiesResults] = useState<
    any[] | null | undefined
  >([]);
  const [specialties, setSpecialties] = useState<any>([]);
  const [additionalFieldsOptions, setAdditionalFieldsOptions] = useState<any>(
    []
  );
  const [clinicData, setClinicData] = useState<any>(null);
  const [showStep, setShowStep] = useState<boolean>(false);
  const [
    prescriptionProfileAdditionalFields,
    setPrescriptionProfileAdditionalFields,
  ] = useState<any>([]);
  const [prescriptionProfileData, setPrescriptionProfileData] = useState<any>(
    {}
  );
  const [medicalLicenseConfiguration, setMedicalLicenseConfiguration] =
    useState<any>([]);
  const [medicalLicenseValue, setMedicalLicenseValue] = useState<any>({});
  const [profession, setProfession] = useState<any>("");
  const [configurations, setConfigurations] = useState<any>(null);
  const [medicalsLicenses, setMedicalsLicenses] = useState<any>(null);
  const [medicalLicenseSelected, setMedicalLicenseSelected] =
    useState<any>(null);
  const [auxMedicalLicenseSelected, setAuxMedicalLicenseSelected] =
    useState<any>(null);
  const [countryId, setCountryId] = useState<any>(null);
  const imgLoadAndCrop: any = useRef();
  const [cropping, setCropping] = useState<boolean>(false);
  const [loadingLicense, setLoadingLicense] = useState<boolean>(false);
  const [sisa, setSisa] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [loadingSyncSisa, setLoadingSyncSisa] = useState<boolean>(false);

  const onSubmit = useCallback(() => {
    if (!medicalLicenseSelected || medicalLicenseSelected.length === 0) {
      showModalInfo(
        locales_es.infoModal.title,
        "Elija una Matricula Profesional"
      );
      return;
    } else {
      onSubmitConfirm();
    }
  }, [
    prescriptionProfileAdditionalFields,
    medicalLicenseConfiguration,
    medicalLicenseValue,
    specialties,
    profession,

    medicalLicenseSelected,
  ]);

  const openCrop = () => {
    imgLoadAndCrop.current.selectImageFile();
  };

  const onSubmitConfirm = () => {
    if (!medicalLicenseSelected || medicalLicenseSelected.length === 0) {
      showModalInfo(
        locales_es.infoModal.title,
        "Elija una Matricula Profesional"
      );
      return;
    }

    const objData = {
      medical_license_id: medicalLicenseSelected,
      prescription_profile_id: prescriptionProfileId,
    };

    if (prescriptionProfileId) {
      api
        .putPrescriptionProfile(prescriptionProfileId, objData)
        .then(() => {
          sendEvent();
          setLoading(false);
          history.push(`/${Routes.OtherInformation}`, {
            userData,
            prescriptionProfileId,
            medicalLicenseId: medicalLicenseSelected,
            hasTwoSteps: false,
            stepTitle:
              clinicData && !clinicData.paylink_enabled
                ? "¡Último Paso!"
                : "Paso 2 de 3",
            stepNumber: 2,
            hasSignup: !!hasSignup,
            firstProfile,
          });
        })
        .catch((err: any) => {
          showModalInfo(locales_es.errorModal.title, helpers.getErrorMsg(err));
          setLoading(false);
        });
    } else {
      history.push(`/${Routes.OtherInformation}`, {
        userData,
        hasTwoSteps: false,
        medicalLicenseId: medicalLicenseSelected,
        hasSignup: !!hasSignup,
        stepTitle:
          clinicData && !clinicData.paylink_enabled
            ? "¡Último Paso!"
            : "Paso 2 de 3",
        stepNumber: 2,
        firstProfile,
      });
    }
  };

  const AddMedicalLicense = () => {
    history.push(`/${Routes.AddMedicalLicense}`, {
      from: history.location.pathname,
      firstProfile,
    });
  };

  const sendEvent = () => {
    EventRegister.emit(EVENT_RELOAD_PRESCRIPTION_PROFILES);
  };

  const onBlur = (inputStr: string) => {
    setErrors(errors.filter((item) => item !== inputStr));
  };

  useEffect(() => {
    if (prescriptionProfileId) {
      api
        .getPrescriptionProfile(prescriptionProfileId)
        .then((res2: any) => {
          setName(res2.data.name);
          setMedicalLicenseSelected(res2.data.medical_license_id);
        })
        .catch((err2: any) => {
          showModalInfo(locales_es.infoModal.title, helpers.getErrorMsg(err2));
        });
    }
    translationService.translate().then((translation) => {
      setTranslations(translation);
    });
    setLoadingLicense(true);
    auth
      .getRemoteUserData()
      .then((res: any) => {
        if (res && res.data && res.data.user) {
          setCountryId(res.data.user.country_id);
          setUserData(res.data.user);

          api
            .getMedicalsLicenses()
            .then((res4: any) => {
              setLoadingLicense(false);
              if (res4 && res4.data && res4.data.length > 0) {
                setMedicalsLicenses(res4.data);
              }
              console.log(res4);
            })
            .catch((err4: any) => {
              setLoadingLicense(false);
              showModalInfo(
                locales_es.infoModal.title,
                helpers.getErrorMsg(err4)
              );
            });
        }
      })
      .catch((err) => {
        setLoadingLicense(false);
        console.log(err);
      });
  }, [load]);

  useEffect(() => {
    // Check if practices are enabled (enabled_certificates)
    const countryService = new CountryService();
    countryService
      .getData()
      .then((res: any) => {
        if (res && res.code === "ar") {
          setHiddenText(false); // Hay un texto que por ahora se muestra solo en ARG
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, []);

  const onSyncSisa = () => {
    if (loadingSyncSisa) return;
    setLoadingSyncSisa(true);
    api
      .syncSISA(userData.identification)
      .then((res: any) => {
        setSisa(true);
        setLoadingSyncSisa(false);
        showModalInfo(
          locales_es.medicalLicenseSync.title,
          locales_es.medicalLicenseSync.message
        );

        EventRegister.emit(EVENT_RELOAD_MEDICAL_LICENSE);
      })
      .catch((err: any) => {
        setLoadingSyncSisa(false);
        showModalInfo(locales_es.errorModal.title, helpers.getErrorMsg(err));
      });
  };

  useEffect(() => {
    const listener = EventRegister.addEventListener(
      EVENT_RELOAD_MEDICAL_LICENSE,
      (data: any) => {
        loadMedicalLicenses();
      }
    );

    return function cleanup() {
      EventRegister.removeEventListener(listener);
    };
  });

  const loadMedicalLicenses = () => {
    setLoadingLicense(true);
    api
      .getMedicalsLicenses()
      .then((res: any) => {
        setLoadingLicense(false);
        if (res && res.data && res.data.length > 0) {
          setMedicalsLicenses(res.data);
        }
      })
      .catch((err: any) => {
        setLoadingLicense(false);
        showModalInfo(locales_es.infoModal.title, helpers.getErrorMsg(err));
      });
  };

  const {
    visible: visibleAdditionalFieldMenuOption,
    open: openAdditionalFieldMenuOption,
    close: closeAdditionalFieldMenuOption,
  } = useModalWithKeyboard(false);

  const uploadImage = (image) => {
    setCropping(false);
    setLoading(true);
    setLoadingText(locales_es.uploadingImage);
    uploadIdentificationImage(image)
      .then(() => {
        setLoading(false);
        setLoadingText("");
        setIdentificationImage({
          uri: image,
        });
      })
      .catch(() => {
        setLoading(false);
        setLoadingText("");
      });
  };

  const handleMedicalLicenseChange = (id: any) => {
    setMedicalLicenseSelected(id);
  };

  return {
    loadingLicense,
    AddMedicalLicense,
    medicalLicenseSelected,
    handleMedicalLicenseChange,
    medicalsLicenses,
    stepTitle,
    hiddenText,
    loading,
    loadingText,
    searchSpecialties,
    searchSpecialtiesResults,
    additionalFieldsOptions,
    imgLoadAndCrop,
    cropping,
    setCropping,
    onSubmit,
    onBlur,
    openCrop,
    visibleAdditionalFieldMenuOption,
    closeAdditionalFieldMenuOption,
    uploadImage,
    theme,
    profession,
    setProfession,
    errors,
    specialties,
    translations,
    prescriptionProfileAdditionalFields,
    userData,
    identificationImage,
    stepNumber,
    medicalLicenseConfiguration,
    clinicData,
    onSyncSisa,
    name,
  };
};

export default useWorkProfilePage;
