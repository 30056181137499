import React, {
  useState,
  useEffect,
  useRef,
} from 'react';
import Please from 'pleasejs';
import {
  View,
  StyleSheet,
  ActivityIndicator,
  FlatList,
  TouchableOpacity,
  Image,
  ScrollView,
  Modal,
  Alert,
} from 'react-native';
import Text from '../../components/Text';
import {useHistory} from 'react-router-dom';
import SearchBox from '../../components/Home/SearchBox';
import {Colors} from '../../configs';
import Theme from '../../style/Theme';
import {getBottomSpace, getStatusBarHeight} from 'react-native-iphone-x-helper';
import {SOURCE_ICON} from '../../images';
import Layout from '../../components/Layout/Layout';
import HeaderButton from '../../components/HeaderButton';

import locales_es from '../../locales/es';
import {CommonStyles} from '../../styles/CommonStyles';
import {api, helpers} from '../../import';
import {showModalInfo} from '../../modules/ViewHelpers';
import PracticeItem from '../../components/PracticeItem';
import TagItem from '../../components/WorkProfile/TagItem';
import scale from '../../utils/scale';
import InputApp from '../../components/InputApp';
import ButtonLinear from '../../components/Buttons/ButtonLinear';
import ButtonBorder from '../../components/Buttons/ButtonBorder';
import {useTheme} from '../../configs/Theme';
import ModalSelect from '../../components/ModalSelect';
import ButtonIcon from '../../components/Buttons/ButtonIcon';
import {
  getFromStorage,
  setInStorage,
  AsyncStorageEnum,
  removeFromStorage,
} from '../../utils/storage';
import {
  EVENT_RELOAD_COMBOS,
  EVENT_SHOW_MODAL_INFO,
  EVENT_RELOAD_COMBOS_PRACTICES
} from '../../constants/Events';
import {MAX_CERTIFICATE_PRESCRIPTIONS} from '../../models/constants';
import {EventRegister} from "../../modules/EventRegister";
import iconHistory from '../../images/ic_history.png';
import iconBack from '../../images/ic_back.png';
import iconFeedActive from '../../images/ic_feed_active.png';
import iconClose from '../../images/ic_close.png';
import MyCombos from '../MyCombos';
import MyPractices from '../MyPractices';
import Overlay from '../../components/Overlay';
import { width } from '../../configs/Const';
import TextInput from '../../components/TextInput';
import DiagnosticAutocompleteItem from '../../components/DiagnosticAutocomplete';

let nativeEventKeySearch = '';
const SearchPractices = (props: any) => {
  const history = useHistory();
  const params = history.location.state;
  const {theme} = useTheme();
  let goBackAlreadyDismissed = false;

  const {items, keyItemsToSearch, prescriptionProfileId, selectedPractices, comboSelected, countPractices}:any =
    props ? props : params ? params : {};

  const onComplete = async (practices:any, comboId?=null,indication?:any,diagnostic?:any) => {
    await setFinished(true);
    let mutableComboSelected = comboSelected && comboSelected.length ? [...comboSelected, comboId] : [comboId];
    props && props.onComplete ? props.onComplete(practices,mutableComboSelected,indication,diagnostic) :
      params &&
      params.onComplete &&
      params.onComplete(practices, mutableComboSelected,indication,diagnostic);
  };
  const [overlay, setOverlay] = useState(false);
  const [keySearch, setKeySearch] = useState('');
  const [searchResults, setSearchResults] = useState(false);
  const [errors, setErrors] = useState([]);
  const [practices, setPractices] = useState([]);
  const [combos, setCombos] = useState([]);
  const [showComboTitleModal, setShowComboTitleModal] = useState(false);
  const [comboTitle, setComboTitle] = useState('');
  const [indications, setIndications] = useState('');
  const [comboIdToView, setComboIdToView] = useState('');
  const [diagnostic, setDiagnostic] = useState('');
  const [diagnosticCod, setDiagnosticCod] = useState('');
  const [nameComboToView, setNameComboToView] = useState('');
  const [newPractice, setNewPractice] = useState<any>({});
  const [medicines, setMedicines] = useState([]);
  const [showModalNewPractices, setShowModalNewPractices] = useState<boolean>(false);
  const [showModalPractices, setShowModalPractices] = useState<boolean>(false);
  const [finished, setFinished] = useState<boolean>(false);
  const [visiblePracticesOption, setVisiblePracticeOptions] =
    useState<boolean>(false);
  const searchInputRef: any = useRef();

  const [historyPracticeSearch, setHistoryPracticeSearch] = useState<Array[]>(
    [],
  );

  const onKeySearchChange = e => {
    nativeEventKeySearch = e.nativeEvent.text;
    setKeySearch(e.nativeEvent.text);
  };

  const onKeySearchClear = () => {
    nativeEventKeySearch = '';
    setKeySearch('');
  };

  const onPressSearchResult = (item, index) => {
    if (item && index == -1) {
      setDiagnostic(`${item.coddiagnostico} - ${item.descdiagnostico}`);
      setDiagnosticCod(item.coddiagnostico);
    } else if (item && index != -1) {
      const _medicines = [...medicines];
      _medicines[index].diagnostic = `${item.coddiagnostico} - ${item.descdiagnostico}`;
      _medicines[index].diagnosticCod = item.coddiagnostico;
      setMedicines(_medicines);
    }
  };
  const handleHistorySearch = async () => {
    let storageHistory = await getFromStorage(
      AsyncStorageEnum.HISTORY_SEARCHPRACTICES,
    );
    const parsedData = JSON.parse(storageHistory) || [];

    if (!parsedData.includes(keySearch)) {
      parsedData.push(keySearch);
      await setInStorage(
        AsyncStorageEnum.HISTORY_SEARCHPRACTICES,
        JSON.stringify(parsedData),
      );
      setHistoryPracticeSearch(parsedData);
    }
  };

  const searchPractices = () => {
    if (keySearch) {
      if (items) {
        // local search
        setSearchResults(null);
        const regex = new RegExp(keySearch, 'i'); // add 'i' modifier that means "ignore case"

        let _items = JSON.parse(JSON.stringify(items));
        const result: any = [];
        _items.map((_item: any) => {
          let founded = false;
          keyItemsToSearch.map((key: string) => {
            if (
              !founded &&
              (regex.test(_item[key]) ||
                keySearch
                  .toLowerCase()
                  .indexOf(String(_item[key]).toLowerCase().trim()) > -1)
            ) {
              result.push(_item);
              founded = true;
            }
          });
        });
        
        setSearchResults(result);
      } else {
        // remote search
        setSearchResults(null);
        const objData = {
          q: keySearch,
          prescription_profile_id: prescriptionProfileId,
        };
        setTimeout(() => {
          if (keySearch.length === nativeEventKeySearch.length) {
            api
              .searchMedicalPractices(objData)
              .then((res: any) => {
                setSearchResults(res.data);
              })
              .catch((err: any) => {
                showModalInfo(
                  locales_es.infoModal.title,
                  helpers.getErrorMsg(err),
                );
              });
          } else {
          }
        }, 800); // 300
      }
    }
  };

  useEffect(() => {
    searchPractices();
    setNewPractice(keySearch);
  }, [keySearch]);

  const confirmExit = (callback) => {
    if (practices.length && !finished && !goBackAlreadyDismissed) {
      EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
        title: locales_es.confirmBackAlert.title,
        message: locales_es.confirmBackAlert.subtitle,
        actions: [
          {
            text: locales_es.confirmBackAlert.cancel,
            onPress: () => console.log('Cancel Pressed'),
            white: true,
          },
          {
            text: locales_es.confirmBackAlert.accept,
            onPress: () => {
              goBackAlreadyDismissed = true;
              callback && callback();
            },
            white: true,
            colors: [Colors.Tomato, Colors.Red],
          },
        ]
      });
      return false;
    } else {
      callback && callback();
    }
  }

  useEffect(() => {
    const unblock = history.block((newLocation, action) => {
      if (action === "POP") {
        confirmExit(history.goBack);
        return true;
      } else {
        // If a "POP" action event occurs,
        // Send user back to the originating location
        // history.go(1);
        return true;
      }
    });

    return () => {
      unblock();
    };

  }, [practices, finished]);

  useEffect(() => {
    getMyCombos();
    getHistorySearch();
  }, []);

  useEffect(() => {
    const listener = EventRegister.addEventListener(
      EVENT_RELOAD_COMBOS,
      combos => {
        if (combos !== undefined) {
          setCombos(combos);
        } else {
          getMyCombos();
        }
      },
    );

    return function cleanup() {
      EventRegister.removeEventListener(listener);
    };
  });

  useEffect(() => {
    const event = EventRegister.addEventListener(EVENT_RELOAD_COMBOS_PRACTICES, () => {
      getMyCombos();
    });
    
    return function cleanup() {
      EventRegister.removeEventListener(event);
    }

  });

  const getMyCombos = () => {

    const objData = {
      prescription_profile_id: prescriptionProfileId,
    };
    if (objData.prescription_profile_id !== null )
    {
      
      api
      .getMyPracticesCombos(objData)
      .then((res: any) => {
        setCombos(res.data);
        
        
      })
      .catch((err: any) => {
        
        showModalInfo(locales_es.infoModal.title, helpers.getErrorMsg(err));
        
      });}
  };

  const getHistorySearch = async () => {
    let getHistoryFromStorage = await getFromStorage(
      AsyncStorageEnum.HISTORY_SEARCHPRACTICES,
    );
    let historySearch = JSON.parse(getHistoryFromStorage);
    const updatedTransactions = [...historySearch];
    setHistoryPracticeSearch(updatedTransactions);
  };

  const showPracticesLimitReachedMessage = () => {
    EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
      title: locales_es.infoModal.title,
      message: locales_es.max_practices_reached,
    });
  };

  const onPressItem = (item: any, index: number) => {
    
    if (practiceAlreadySelected(item)) {
      removePractice(item);
      return;
    }
    item.quantity = 1;
    item.is_censionated = false;
    const _practices = JSON.parse(JSON.stringify(practices));
    
    if (
      _practices.length >= MAX_CERTIFICATE_PRESCRIPTIONS ||
      _practices.length + countPractices >=
      MAX_CERTIFICATE_PRESCRIPTIONS
    ) {
      showPracticesLimitReachedMessage();
      return;
    }
    
    _practices.push(item);
    
    setPractices(_practices);

    // This was a handfull search, so we record the keyword in history
    if (keySearch && keySearch.length >= 3) {
      handleHistorySearch();
    }

    return;
  };

  const onPressItemEdited = (item: any, index: number) => {
    
    if (practiceAlreadySelected(item)) {
      showModalInfo(locales_es.practiceSelectedAlreadyExist.title, 
        locales_es.practiceSelectedAlreadyExist.customMessage({CUSTOM_MESSAGE: item.name}))
        
      return;
    }
    const _practices = JSON.parse(JSON.stringify(practices));

    if (
      _practices.length >= MAX_CERTIFICATE_PRESCRIPTIONS ||
      _practices.length + countPractices >=
      MAX_CERTIFICATE_PRESCRIPTIONS
    ) {
      showPracticesLimitReachedMessage();
      return;
    }
    _practices.push(item);
    setPractices(_practices);
    return;
  };
  

  const removePractice = item => {
    const _practices = JSON.parse(JSON.stringify(practices));
    let _index = null;
    practices.map((practice, index) => {
      if (Number(practice.id) === Number(item.id)) {
        _index = index;
      }
    });
    if (_index !== null) {
      _practices.splice(_index, 1);
      setPractices(_practices);
    }
  };

  const practiceAlreadySelected = item => {
    let _index = null;
    practices.map((practice, index) => {
      if (Number(practice.id) === Number(item.id)) {
        _index = index;
      }
    });
    return _index !== null;
  };

  const renderItem = ({item}) => {
    return (
      <PracticeItem
        item={item}
        onPress={() => onPressItem(item)}
        selected={practiceAlreadySelected(item)}
      />
    );
  };

  const selectItemFromModal = (item) =>{
    onComplete([item])
  }

  const renderComboList = () => {
    const colors: any = [];
    let hue = 100;
    combos.map((combo: any, index: number) => {
      hue +=
        index * 3 + combos.length + combo.practices.length + combo.name.length;
      colors.push(
        Please.make_color({
          hue: hue > 0 && hue < 360 ? hue : combos.length, // arbitrary default number
          saturation: 0.75,
          value: 0.65,
          base_color: '',
          greyscale: false,
          grayscale: false,
          golden: false,
          full_random: false,
          colors_returned: 1,
          format: 'hex',
        })[0].toString(),
      );
    });

    const renderCombo = ({item, index}) => {
      
      return (
        <TouchableOpacity
          {...item}
          style={{
            alignContent: 'center',
            marginRight: 20,
            alignItems: 'center',
          }}
          key={item.id.toString()}
          onPress={() => {
            openPraticesOptions(item.id, item.name);
          }}>
          <View
            style={[styles.badge, {backgroundColor: colors[index].toString()}]}>
            <Text size={24} bold white center>
              {item.practices.length}
            </Text>
            <Text white size={12} center>
              {locales_es.practices}
            </Text>
          </View>
          <View style={styles.practiceText}>
            <Text size={14} lineHeight={18} center>
              {item.name}
            </Text>
          </View>
        </TouchableOpacity>
      );
    };
    return (
      <FlatList
        data={combos.slice(0, 10)}
        renderItem={renderCombo}
        horizontal={true}
      />
    );
  };

  const onPressPracticeX = index => {
    const items = JSON.parse(JSON.stringify(practices));
    items.splice(index, 1);
    setPractices(items);

    if (!items.length) {
      setShowModalPractices(null);
    }
  };

  const renderSelectedPractices = ({item, index}) => {
    return (
      <TagItem
        title={`${item.name}`}
        key={index.toString()}
        onPressX={() => onPressPracticeX(index)}
      />
    );
  };

  const addCombo = () => {
    if (!comboTitle) {
      setErrors(['comboTitle']);
      return;
      
    }
    

  if (combos.length > 0) {

  const filterCombos = combos.filter(x => x.name === comboTitle);

      if (filterCombos.length > 0) {
          getMyCombos();
          setComboTitle('');
          setShowComboTitleModal(false);
          showModalInfo(locales_es.noComboSuccess.title, locales_es.noComboSuccess.checkData);
        return;
      }
      
      const objData = {
        prescription_profile_id: prescriptionProfileId,
        name: comboTitle,
        practices: practices.map((practice: any) => practice.id),
        indications: indications? indications : '',
        diagnostic: diagnostic? diagnostic : '',
      };
      api
        .postCombo(objData)
        .then((res: any) => {
          getMyCombos();
          setComboTitle('');
          setShowComboTitleModal(false);
          onComplete(practices,null,indications,diagnostic);
        })
        .catch((err: any) => {
          
          helpers.processError(err);
          Alert.alert(locales_es.errorModal.title, helpers.getErrorMsg(err));
      });

    } else {
      const objData = {
        prescription_profile_id: prescriptionProfileId,
        name: comboTitle,
        practices: practices.map((practice: any) => practice.id),
        indications: indications, 
      };
      api
        .postCombo(objData)
        .then((res: any) => {
          getMyCombos();
          setComboTitle('');
          setShowComboTitleModal(false);
          onComplete(practices,null,indications,diagnostic);
        })
        .catch((err: any) => {
          helpers.processError(err);
          Alert.alert(locales_es.errorModal.title, helpers.getErrorMsg(err));
      });
    }
  
  };

  const addNewPractice = () => {
    
    if (newPractice.length + countPractices > MAX_CERTIFICATE_PRESCRIPTIONS){
      showPracticesLimitReachedMessage()
      setShowModalNewPractices(false)
      return;
    }
    if (!newPractice) {
      setErrors(['newPracticeName']);
      return;
    }

    if (newPractice.length > 0) {
      //verificamos si ya existe la practica
      const filterPractices = practices.filter(x => x.name === newPractice);

      if (filterPractices.length > 0) {
        showModalInfo("Error", "La practica se encuentra en la lista de seleccionadas");
        setShowModalNewPractices(false);
        return;
      }

      const objData = {
        name: newPractice,
        is_censionated: false,
        quantity: 1,
      }

      practices.push(objData);

      setNewPractice('');
      setShowModalNewPractices(false);
      setPractices(practices);

    }else{
      const objData = [{
        name: newPractice,
        is_censionated: false,
        quantity: 1,
      }];
  
      setNewPractice('');
      setShowModalNewPractices(false);
      setPractices(practices.concat(objData));
    }

  };

  const openPraticesOptions = (id: string, name?: string) => {
    
    setComboIdToView(id);
    setNameComboToView(name? name : '');
    setVisiblePracticeOptions(true);
  };

  const getOptionsPractices = () => {
    if (comboIdToView !== '') {
      const filterCombo = combos.find(x => x.id === comboIdToView);
      return filterCombo.practices.map(x => {
        return {name: x.medical_practice.name, id: x.medical_practice.id};
      });
      
    }
    return [];
    
  };

  const closeModalPracticesOption = () => {
    setComboIdToView('');
    setNameComboToView('');

    setVisiblePracticeOptions(false);
  };

  const renderHistorySearch = () => {
    const search = str => {
      setKeySearch(str);
      nativeEventKeySearch = str;
    };

    return historyPracticeSearch.map(pr => {
      return (
        <TouchableOpacity
          onPress={() => {
            search(pr);
          }}
          style={{
            paddingVertical: 10,
            flexDirection: 'row',
          }}>
          <View style={styles.roundedIcon}>
            <Image
              source={iconHistory}
              style={{width: 30, height: 30}}
            />
          </View>
          <Text style={styles.historyText}>{pr}</Text>
          <TouchableOpacity
            onPress={() => {
              search(pr);
              searchInputRef.current.focus();
            }}
            style={{
              justifyContent: 'center',
              alignSelf: 'center',
              position: 'absolute',
              right: 10,
              transform: [{rotate: '45deg'}],
            }}>
            <Image
              source={iconBack}
              style={[{tintColor: Colors.GrayBlue}]}
            />
          </TouchableOpacity>
        </TouchableOpacity>
      );
    });
  };

  const clearHistorySearch = async () => {
    await removeFromStorage(AsyncStorageEnum.HISTORY_SEARCHPRACTICES);
    setHistoryPracticeSearch([]);
  };

  const filterIfAlreadySelected = (items) => {
    
    if (practices.length == 0) {
      setPractices(items)
   }
   else {
    const dataFiltered = items.filter((el)=> {
      return !practices.some(item => item.id === el.id)
    })
    
    setPractices(practices.concat(dataFiltered))
    
   }
   
  }

  const renderModalFooterBtn = () => {
    return (
      <ButtonLinear
        white
        style={{
          flex: 1,
          justifyContent: 'center',
          paddingLeft: 40,
          paddingRight: 10,
        }}
        styleButton={{height: 80}}
        title={`Ver práctica${practices.length > 1 ? 's' : ''} seleccionada${
          practices.length > 1 ? 's' : ''
        } `}
        children={
          showModalPractices ? (
            <View
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                flex: 1,
              }}
            />
          ) : (
            <View style={styles.circle}>
              <Text style={{color: '#fff', textAlign: 'center'}}>
                {practices.length}
              </Text>
            </View>
          )
        }
        onPress={() => setShowModalPractices(true)}
      />
    );
  };

  const openModalTitleCombo = () => {

    const noId = practices.filter((practice: any) => 
      //buscamos si hay practicas sin id
      practice.id === undefined 
    );

    if (noId.length > 0) {

      const noIdName = noId.map((practice: any) => {
        return practice.name + `${noId.length > 1 ? ', ' : ''}`;
      });

      showModalInfo(
        locales_es.noComboSuccess.title, 
        "Solo se pueden guardar prácticas seleccionadas del maestro de prácticas, por favor verifique las siguientes prácticas: " + noIdName
        );
      setShowModalPractices(false);
      return
    }

    setShowModalPractices(false);
    setShowComboTitleModal(true);

  };

  const renderAddNewPractice = () => {
    return (
        <ButtonLinear
          white
          styleButton={{
            flex: 1,
            marginTop: 30,
            paddingHorizontal: 20,
            width: '35%',
            alignSelf: 'center',
          }}
          onPress={() => {
            setShowModalNewPractices(true);
          }}
          title={'Añadir práctica'}
        />
    );
  };

  const modalNewPractice = () => {
    return (
      <Modal visible={true} transparent animationType={'fade'}>
        <View style={styles.modalContainer}>
          <Text size={20} lineHeight={24} bold marginTop={scale(55)}>
            Añadir nueva práctica
          </Text>
          <View style={{width: '50%',marginTop: 30}}>
            <InputApp
              onChangeText={setNewPractice}
              value={newPractice}
              borderColor={
                errors.includes('newPracticeName')
                  ? theme.lineErrorColor
                  : theme.innearColor
              }
              onBlur={() => onBlur('newPracticeName')}
              blurOnSubmit={true}
              autoCapitalize="words"
              returnKeyType="go"
              autoFocus={false}
              onSubmitEditing={addNewPractice}
              title='Nombre de la práctica'
              showRequired={true}
            />
            {
                errors.includes('newPracticeName') &&  (
                  <Text style={{
                    color: theme.lineErrorColor,
                    marginTop: 10,
                  }} center size={15}>{locales_es.noNewPractrice.checkData}</Text>
                )
              }
          </View>

          <ButtonLinear
            white
            title={'Guardar'}
            style={{width: 400}}
            onPress={addNewPractice}
            disabled={!newPractice}
            styleButton={{width: 400}}
          />
          <ButtonBorder
            style={{marginTop: 10,width: 400}}
            title={'Volver'}
            onPress={() => {
              setShowModalNewPractices(false);
            }}
            placeholder
          />
        </View>
      </Modal>
    )
  };

  const responsiveWidth = () => { 
    const {innerWidth: width} = window;
    
      return width
            }

  
  return (
    <>
      {overlay && <Overlay style={{ left: 0, top: 0, height: 1300}}>{overlay}</Overlay>}

      <Layout style={styles.header}>
        <HeaderButton
          onPress={() => confirmExit(props.onGoBack)}
          icon={SOURCE_ICON.close}
          style={{
            marginTop: 26,
            marginLeft: 24,
          }}
        />
        <SearchBox
          forwardRef={searchInputRef}
          placeholder={'Buscar prácticas...'}
          value={keySearch}
          onChange={onKeySearchChange}
          borderColor={Colors.TealBlue}
          shadow={false}
          style={styles.searchBox}
          autoFocus={true}

          onClear={onKeySearchClear}
        />
      </Layout>
      
      <View
        style={{
          ...Theme.flexOne,
          paddingTop: 100,
          paddingBottom: 160,
          justifyContent: 'flex-start',
          zIndex: 1,
        }}>
        {!keySearch ? (
          <ScrollView
            showsVerticalScrollIndicator={false}
            style={{
              marginTop: 20,
              padding: 3,
              marginBottom: practices.length ? 120 : 0,
            }}>
            {combos && combos.length ? (
              <View style={styles.containerCombo}>
                <View style={{...Theme.flexRowSpace, marginBottom: 20}}>
                  <Text size={13} lineHeight={16} bold>
                    Mis Combos
                  </Text>
                  <TouchableOpacity
                    onPress={() =>
                      setOverlay(
                        <MyCombos
                          showMainModal={(title, content, actions, customStyles, contentCustomClassName) => props.showMainModal(title, content, actions, customStyles, contentCustomClassName)}
                          hideMainLoading={props.hideMainLoading}
                          onComplete={_practices => {
                            filterIfAlreadySelected(_practices)
                            
                            setOverlay(null);
                            setShowModalPractices(true);
                          }}
                          prescriptionProfileId={prescriptionProfileId}
                          onGoBack={() => setOverlay(null)}/>
                      )}>
                    <Text size={13} lineHeight={16} color={Colors.DodgerBlue}>
                      Ver todos
                    </Text>
                  </TouchableOpacity>
                </View>
                {renderComboList()}
              </View>
            ) : (
              <Text size={13} lineHeight={16} bold center>
                {locales_es.typeToSearch}...
              </Text>
            )}

            <View style={styles.containerHistory}>
              {historyPracticeSearch.length > 0 && (
                <>
                  <View style={{...Theme.flexRowSpace, marginVertical: 20}}>
                    <Text size={13} lineHeight={16} bold>
                      Búsquedas Recientes
                    </Text>
                    <TouchableOpacity onPress={clearHistorySearch}>
                      <Text size={13} lineHeight={16} color={Colors.DodgerBlue}>
                        Limpiar Historial
                      </Text>
                    </TouchableOpacity>
                  </View>
                  {renderHistorySearch()}
                </>
              )}
            </View>
          </ScrollView>
        ) : searchResults === null ? (
          <ActivityIndicator
            style={[CommonStyles.activityMonitor, {marginTop: 20}]}
          />
        ) : searchResults && searchResults.length ? (
          <FlatList
            data={searchResults}
            renderItem={renderItem}
            scrollEventThrottle={16}
            contentContainerStyle={styles.contentContainerStyle}
            showsVerticalScrollIndicator={false}
          />
        ) : (
          <>
            <Text size={13} lineHeight={16} bold center marginTop={20}>
            {locales_es.searchNotFound + " con " + keySearch}
            </Text>

            {renderAddNewPractice()}
          </>

        )}
      </View>

      {practices.length > 0 && (
        <View style={{
          ...styles.bottomFixedWrapper,
          width: '60%',
          marginLeft: '5%',
        }}>{renderModalFooterBtn()}</View>
      )}

      {showComboTitleModal &&
        <Modal visible={true} transparent animationType={'fade'}>
          <View style={styles.modalContainer}>
            <View style={styles.modalContainerContent}>
              <View style={{
                width: '100%',
                position:'abosulute',
                left: 80,
                bottom: 90
                }}>
                <Text size={20} lineHeight={24} marginLeft={165} bold marginTop={scale(48)} style={{padding: 15}}>
                  Asígnele un nombre
                </Text>
                <View style={{width: '40%'}}>
                  <InputApp
                    onChangeText={setComboTitle}
                    value={comboTitle}
                    borderColor={
                      errors.includes('comboTitle')
                        ? theme.lineErrorColor
                        : theme.innearColor
                    }
                    blurOnSubmit={true}
                    autoCapitalize="words"
                    returnKeyType="go"
                    autoFocus={false}
                    onSubmitEditing={addCombo}
                  />
                </View>
                {errors.includes('comboTitle') ?
                <Text size={10} lineHeight={24} marginLeft={195} marginTop={16} color='red'>
                  Ingrese un nombre para el combo.
                </Text>
                : null
                }
                
                <Text size={20} lineHeight={24} marginLeft={160} bold marginTop={scale(10)}>
                  Diagnóstico (opcional)
                </Text>
                <View style={{ height: 50,width: '30%', zIndex: 1000,padding: 10,marginLeft: 70 }}>
                  <DiagnosticAutocompleteItem
                          diagnosticIn={diagnostic}
                          diagnosticCodIn={diagnosticCod}
                          buttonIconFlag={true}
                          errorsIn={[]}
                          tagIn={'prescription-medicine'}
                          paddingRightFlagIn={true}
                          onPress={(item) =>  onPressSearchResult(item, -1)}
                          onChange={(text) => setDiagnostic(text)}
                        />
                </View>
              </View>
              <Text size={20} lineHeight={24} bold marginTop={scale(12)} 
              style={{
                position:'Absolute',
                right:220,
                bottom: 530}}>
                Asígnale indicaciones (opcional)
              </Text>
              <View
              style={{
                ...Theme.flexRow,
                marginTop: 30,
                width: '35%',
                position:'Absolute',
                right:70,
                bottom: 340
              }}
            >
              <TextInput
                placeholderTextColor={theme.placeholder}
                placeholder={"Indicaciones para el combo de prácticas..."}
                value={indications}
                style={{
                  fontFamily: "Muli_400Regular",
                  fontSize: 15,
                  lineHeight: 18,
                  color: theme.text,
                  height: 150,
                  flex: 1,
                  borderWidth: 1,

                }}
                onChangeText={setIndications}
                multiline={true}
                editable={true}
              />
            </View>
              <Text size={15} lineHeight={24} marginTop={16} center>
                De esta manera, podrá reconocer este combo de prácticas y
                reutilizar rápidamente aquellos que utiliza con más frecuencia.
              </Text>
              <ButtonLinear
                white
                title={'Guardar'}
                style={{paddingHorizontal: 32}}
                onPress={addCombo}
              />
              <ButtonBorder
                style={{marginTop: 10, paddingHorizontal: 32}}
                title={'Volver'}
                onPress={() => {
                  setShowComboTitleModal(false);
                  setShowModalPractices(true);
                }}
                placeholder
              />
            </View>
          </View>
        </Modal>
      }

      {showModalPractices &&
        <Modal visible={true} transparent animationType={'fade'}>
          <>
            <View style={styles.modalShowPracticesContainer}>
              <Layout
                style={{
                  borderRadius: 16,
                  paddingHorizontal: 24,
                  paddingVertical: 32,
                  height: '50%',
                  maxHeight: 400,
                  paddingBottom: 100,
                  width: responsiveWidth() === 1440 ? '43%' : '50%',
                  margin: 'auto',
                }}>
                <View style={{flexDirection: 'row'}}>
                  <View style={{width: '90%', flexDirection: 'row'}}>
                    <Image
                      source={iconFeedActive}
                      style={{width: 32, height: 32, marginRight: 5}}
                    />
                    <View style={{flexDirection: 'column'}}>
                      <Text left size={17} lineHeight={20} bold>
                        Has cargado
                      </Text>
                      <Text left size={13} lineHeight={20} color={'#36c6c8'}>
                        {`${practices.length} Práctica${
                          practices.length > 1 ? 's' : ''
                        }`}
                      </Text>
                    </View>
                  </View>
                  <View style={{width: '10%'}}>
                    <TouchableOpacity
                      onPress={() => setShowModalPractices(false)}>
                      <Image
                        source={iconClose}
                        style={{width: 20, height: 20}}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
                <ScrollView style={{paddingVertical: 20}}>
                  <FlatList
                    data={practices}
                    renderItem={renderSelectedPractices}
                    showsVerticalScrollIndicator={false}
                  />
                </ScrollView>
                <View
                  style={{
                    position: 'absolute',
                    bottom: 20,
                    alignSelf: 'center',
                    
                  }}>
                  <ButtonIcon
                    icon={'ic_rate_full'}
                    tintColor="#fff"
                    style={{
                      backgroundColor: '#13ad69',
                      height: 48,
                      width: '100%',
                      alignSelf: 'center',
                    }}
                    imgStyle={{
                      width: 20
                    }}
                    onPress={() => {

                      openModalTitleCombo();
                    }}
                    text={'Guardar combo de prácticas'}
                  />
                </View>
              </Layout>
            </View>
            <View

              style={{
                ...styles.bottomFixedWrapper,
                backgroundColor: '#fff',
                width: '40%',
                margin: 'auto',
                display: 'block',
                left: 0,
                right: 0,
                borderTopLeftRadius: 16,
                borderTopRightRadius: 16,
              }}
              >
              <ButtonLinear
                white
                colors={['#00d75b', '#00d75b']}
                title={locales_es.confirm_selected}
                onPress={() => onComplete(practices)}
              />
            </View>
          </>
        </Modal>
      }

      {visiblePracticesOption &&
        <Modal
          visible={true}
          onRequestClose={() => closeModalPracticesOption()}
          transparent
          animationType={'fade'}>
          <ModalSelect
            style={
              {
                width: '700px',
                alignSelf: 'center'
              }
            }
            onPressItem={item => {
              selectItemFromModal(item)
            }}
            choices={getOptionsPractices()}
            close={() => closeModalPracticesOption()}
            customChoice={{
              name: 'Usar Combo',
              onPress: () => {
                if (comboIdToView !== '') {
                  

                  const filterCombo = combos.find(x => x.id === comboIdToView);

                  if(filterCombo.practices.length + countPractices > MAX_CERTIFICATE_PRESCRIPTIONS) {
                    showPracticesLimitReachedMessage();
                    setVisiblePracticeOptions(false)
                    return;
                  }
                  onComplete(
                    filterCombo.practices.map(x => {
                      x.medical_practice.is_censionated = false;
                      x.medical_practice.quantity = 1;
                      return x.medical_practice;
                    }),
                    
                    comboIdToView,filterCombo.indications,filterCombo.diagnostic
                  );
                }
              },
              disabled: comboSelected && comboSelected.length && comboSelected.includes(comboIdToView) ? true : false,
            }}
            customEditCombo={
              {
                name: 'Editar Combo',
                onPress: () => {
                  setVisiblePracticeOptions(false);
                  setOverlay(
                    <MyPractices
                      showMainModal={(title, content, actions, customStyles, contentCustomClassName) => props.showMainModal(title, content, actions, customStyles, contentCustomClassName)}
                      hideMainLoading={props.hideMainLoading}
                      onComplete={_practices => {
                        setPractices(practices.concat(_practices));
                        setOverlay(null);
                        setShowModalPractices(true);
                      }}
                      prescriptionProfileId={prescriptionProfileId}
                      onGoBack={() => setOverlay(null)}
                      comboIdToView={comboIdToView}
                      nameComboToView={nameComboToView}
                      />
                  )
                },
              }
            }
          />
        </Modal>
      }

      {
        showModalNewPractices && modalNewPractice()
      }
    </>
  );
};

export default SearchPractices;

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 24,
    ...Theme.flexOne,
  },
  contentContainerStyle: {
    paddingHorizontal: 24,
    paddingTop: 30,
    paddingBottom: getBottomSpace(),
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
  modalShowPracticesContainer: {
    backgroundColor: Colors.Black68,
    ...StyleSheet.absoluteFillObject,
    paddingHorizontal: 24,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    justifyContent: 'center',
  },
  containerModal: {
    backgroundColor: Colors.Black68,

    paddingHorizontal: 24,
    justifyContent: 'center',
  },
  containerCombo: {
    paddingHorizontal: 24,
  },
  containerHistory: {
    paddingTop: 20,
    marginBottom: 10,
    paddingBottom: 20,
    paddingHorizontal: 24,
  },
  scrollView: {
    flex: 1,
  },
  header: {
    ...Theme.flexRowSpace,
    paddingRight: 24,
    position: 'fixed',
    top: 0,
    width: '78%',
    zIndex: 100,
  },
  cancelButton: {
    height: 48,
    marginTop: getStatusBarHeight(),
  },
  searchBox: {
    marginLeft: 24,
    marginBottom: 12,
    flex: 1,
  },
  badge: {
    width: 78,
    paddingVertical: 20,
    paddingHorizontal: 5,
    borderRadius: 17,
  },
  rightColumn: {
    alignItems: 'flex-end',
    justifyContent: 'center',
    height: 40,
    borderRadius: 8,
  },
  profileListIcon: {
    marginHorizontal: 5,
  },
  practiceText: {
    paddingTop: 10,
    maxWidth: 100,
    maxHeight: 100,
  },
  spec: {
    marginTop: 8,
    ...Theme.flexColumn,
    flexWrap: 'wrap',
    paddingBottom: 16,
    justifyContent: 'center',
    borderBottomColor: Colors.TealBlue,
    borderBottomWidth: 1,
  },
  specCombo: {
    borderColor: Colors.TealBlue,
    borderWidth: 1,
    padding: 10,
    borderRadius: 10,
    borderStyle: 'dashed',
  },
  wrapperButtonAdd: {marginBottom: 30},
  modalContainer: {
    ...Theme.container,
    ...Theme.center,
    flex: 1,
    position: 'absolute',
    zIndex: 900,
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    backgroundColor: '#ffff',
  },
  modalContainerContent: {
    ...Theme.container,
    ...Theme.center,
    flex: 1,
    position: 'absolute',
    zIndex: 900,
    top: 0,
    right: 0,
    left: 0,
    bottom: 160,
    backgroundColor: '#ffff',
  },
  bottomFixedWrapper: {
    position: 'fixed',
    bottom: 0,
    zIndex: 100,
    width: '100%',
    paddingHorizontal: 24,
    paddingBottom: 52,
    backgroundColor: 'rgba(255,255,255,.75)',
  },
  roundedIcon: {
    width: 40,
    height: 40,
    backgroundColor: '#1ea1f3',
    borderRadius: 8,
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 10,
  },
  historyText: {
    marginLeft: 15,
    marginTop: 10,
  },
  circle: {
    borderWidth: 1,
    borderColor: '#fff',
    height: 30,
    width: 30,
    borderRadius: 15,
    justifyContent: 'center',
  },
});
