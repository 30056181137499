import React from "react";
import { StyleSheet, Modal, Image, ActivityIndicator } from "react-native";
import Text from "../../../components/Text";
import HeaderButton from "../../../components/HeaderButton";
import Theme from "../../../style/Theme";
import scale from "../../../utils/scale";
import InputApp from "../../../components/InputApp";
import ButtonLinear from "../../../components/Buttons/ButtonLinear";
import Container from "../../../components/Layout/Container";
import PrefixSelector from "../../../components/PrefixSelector";
import ModalSelect from "../../../components/ModalSelect";
import FullScreenLoading from "../../../components/fullscreenLoading";
import locales_es from "../../../locales/es";
import {
  getBottomSpace,
  getStatusBarHeight,
} from "react-native-iphone-x-helper";
import Content from "../../../components/Layout/Content";
import { useTheme } from "../../../configs/Theme";
import TextInput from "../../../components/TextInput";
import ImgLoadAndCrop from "../../../components/imgLoadAndCrop";
import iconNext from "../../../images/ic_next.png";
import useBasicInformationPage from "../shared/useBasicInformationPage";
import RequireData from "../../../components/RequireData";
import RequireIndicator from "../../../components/RequireIndicator";
import ButtonIcon from "../../../components/Buttons/ButtonIcon";
import Colors from "../../../configs/Colors";

interface BasicInformationProps {}

const BasicInformationMobile = (props: BasicInformationProps) => {
  const { theme } = useTheme();
  const {
    profileMode,
    profileImage,
    uploadProfileImage,
    setCropping,
    professionalPrefixId,
    professionalPrefixes,
    onPressPrefixSelectorItem,
    errors,
    identificationType,
    identification,
    identificationRef,
    onBlur,
    showNonEditableInfo,
    openIdentificationTypesMenuOption,
    locked,
    setIdentification,
    nameRef,
    name,
    setName,
    setLastname,
    setIdTributario,
    idTributarioRef,
    idTributario,
    lastname,
    lastnameRef,
    onSubmit,
    visibleIdentificationTypesMenuOption,
    closeIdentificationTypesMenuOption,
    setIdentificationType,
    identificationTypes,
    loading,
    loadingText,
    cropping,
    enableButton,
    minDocLength,
    maxDocLength,
    countryId,
    showModalSISAInfo,
    SISAVerification,
    setMinDocLength,
    setMaxDocLength,
    setSISAVerification,
    handleSISAVerification,
    handleValidatorInputsTypes,
    errorMessages
  } = useBasicInformationPage();

  return (
    <Container style={styles.container}>
      {loading && <FullScreenLoading text={loadingText}/>}
      <Content
        style={styles.container}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{
          paddingHorizontal: 24,
          paddingBottom: getBottomSpace() + 16,
          paddingTop: getStatusBarHeight() + 22,
        }}>
        {!cropping &&
          <>
            <HeaderButton style={{position: 'relative'}}/>

            <Text size={13} lineHeight={16} bold marginTop={32}>
              {profileMode ? 'Editar Perfil' : 'Paso 1 de 3'}
            </Text>
            <Text size={24} lineHeight={28} bold marginTop={16} marginBottom={8}>
              Información Básica
            </Text>
            <RequireData />
            {!profileMode && (
              <Text size={13} lineHeight={22} marginTop={16}>
                Completa estos datos para comenzar a generar tu recetario. Son datos
                mínimos necesarios
              </Text>
            )}

          </>
        }
        <ImgLoadAndCrop
          image={profileImage}
          inputLabel={"Imagen de perfil"}
          imageId={"profileImage"}
          imageState={"profileImage"}
          inputWrapperCustomClassName="col-12 pl-md-0"
          canvasCustomClassName="profile"
          aspect="1"
          saveCrop={uploadProfileImage}
          deleteCrop={() => console.log('deleteCrop')}
          deletedImage={() => console.log('deleteImage')}
          onCropping={() => setCropping(true)}
          onStopCropping={() => setCropping(false)}
        />

        {!cropping &&
          <>
            <PrefixSelector
              prefixes={professionalPrefixes}
              professionalPrefixId={professionalPrefixId}
              onPressItem={onPressPrefixSelectorItem}
              style={{
                marginTop: 38,
              }}
              containerStyle={
                errors.includes('professionalPrefixId')
                  ? {
                      borderWidth: 1,
                      borderRadius: 10,
                      borderColor: theme.lineErrorColor,
                    }
                  : null
              }
              showRequire = {true}
            />
            {
              errors.includes('professionalPrefixId') && <Text size={13} lineHeight={16} color='red' marginTop={5} marginLeft={5}>{errorMessages.required}</Text>
            }
            {identificationType ? (
              <InputApp
                key="identificationType"
                title="Tipo de Identificación"
                marginTop={24}
                marginBottom={4}
                value={identificationType.name}
                editable={false}
                disabled={locked}
                onPress={() => {
                  locked
                    ? showNonEditableInfo()
                    : openIdentificationTypesMenuOption();
                }}
                borderColor={
                  errors.includes('identificationType')
                    ? theme.lineErrorColor
                    : theme.innearColor
                }
                showRequired={true}
              />
            ) : (
              <ActivityIndicator/>
            )}

            <Text type="H6" semibold marginTop={24} marginBottom={4}>
              {locales_es.identification} <RequireIndicator />
            </Text>
            <TextInput
              onPress={() => {
                if (locked) {
                  showNonEditableInfo();
                }
              }}
              borderColor={
                errors.includes('identification')
                  ? theme.lineErrorColor
                  : theme.innearColor
              }
              editable={!locked}
              disabled={locked}
              forwardRef={identificationRef}
              value={identification || ''}
              placeholder={locales_es.identification}
              onChangeText={(value:string) => {
                handleValidatorInputsTypes(value,'number',setIdentification,'identification',minDocLength)
              }}
              onBlur={(e:any) => {
                const {value} = e.target;
                if(countryId === 1 && identificationType && identificationType.id === 1 && identificationType.name == 'DNI'){
                  handleSISAVerification(value)
                }
              }}
              keyboardType="numeric"
              returnKeyType="go"
              onSubmitEditing={() => {
                nameRef.current.focus();
              }}
              showRequired={true}
              maxLength={maxDocLength}
            />
              {
                errors.includes('identification') && <Text size={13} lineHeight={16} color='red' marginTop={5} marginLeft={5}>{errorMessages.identification}</Text>
              }
              {
              countryId === 1 && identificationType && identificationType.id === 1 && identificationType.name == 'DNI' && (
                  <Text size={13} lineHeight={16} marginTop={16} color='gray' marginLeft={5} marginRight={5}
                  onPress={showModalSISAInfo} style={styles.alingButtonSISA}
                >
                    Veficado en el SISA
                    <ButtonIcon
                      icon={'help'}
                      tintColor={Colors.WhiteGray}
                      style={styles.buttonIcon}
                      onPress={showModalSISAInfo}
                    />
              </Text>
                )
              }
              {
                SISAVerification && <Text size={13} lineHeight={16} color={Colors.Info} marginTop={5} marginLeft={5}>{
                  locales_es.SISARejected.subtitle
                }</Text>
              }


            <InputApp
              forwardRef={idTributarioRef}
              title={locales_es.idTributario}
              placeholder={locales_es.idTributario}
              value={idTributario}
              marginTop={24}
              onChangeText={(value:string) => {handleValidatorInputsTypes(value,'cuit',setIdTributario,'idTributario',11)}}
              borderColor={
                errors.includes('idTributario')
                  ? theme.lineErrorColor
                  : theme.innearColor
              }
              blurOnSubmit={true}
              onSubmitEditing={onSubmit}
              autoCapitalize="words"
              returnKeyType="go"
              autoFocus={false}
              showRequired={true}
              maxLength={20}
            />
            {
              errors.includes('idTributario') && <Text size={13} lineHeight={16} color='red' marginTop={5} marginLeft={5}>{errorMessages.idTributario}</Text>
            }




            <InputApp
              forwardRef={idTributarioRef}
              title={locales_es.idTributario}
              placeholder={locales_es.idTributario}
              value={idTributario}
              marginTop={24}
              onChangeText={(value:string) => {handleValidatorInputsTypes(value,'cuit',setIdTributario,'idTributario',13)}}
              borderColor={
                errors.includes('idTributario')
                  ? theme.lineErrorColor
                  : theme.innearColor
              }
              blurOnSubmit={true}
              onSubmitEditing={onSubmit}
              autoCapitalize="words"
              returnKeyType="go"
              autoFocus={false}
              showRequired={true}
              maxLength={13}
            />
            {
              errors.includes('idTributario') && <Text size={13} lineHeight={16} color='red' marginTop={5} marginLeft={5}>{errorMessages.idTributario}</Text>
            }




            <InputApp
              forwardRef={nameRef}
              marginTop={24}
              title={locales_es.fullname}
              value={name}
              onChangeText={(value:string) => {handleValidatorInputsTypes(value,'letter',setName,'name',2)}}
              borderColor={
                errors.includes('name') ? theme.lineErrorColor : theme.innearColor
              }
              placeholder={locales_es.fullname}
              blurOnSubmit={true}
              onSubmitEditing={() => {
                lastnameRef.current.focus();
              }}
              autoCapitalize="words"
              returnKeyType="next"
              autoFocus={false}
              showRequired={true}
              maxLength={20}
            />
            {
              errors.includes('name') && <Text size={13} lineHeight={16} color='red' marginTop={5} marginLeft={5}>{errorMessages.name}</Text>
            }


            <InputApp
              forwardRef={lastnameRef}
              title={locales_es.lastname}
              placeholder={locales_es.lastname}
              value={lastname}
              marginTop={24}
              onChangeText={(value:string) => {handleValidatorInputsTypes(value,'letter',setLastname,'lastname',4)}}
              borderColor={
                errors.includes('lastname')
                  ? theme.lineErrorColor
                  : theme.innearColor
              }
              blurOnSubmit={true}
              onSubmitEditing={onSubmit}
              autoCapitalize="words"
              returnKeyType="go"
              autoFocus={false}
              showRequired={true}
              maxLength={20}
            />
            {
              errors.includes('lastname') && <Text size={13} lineHeight={16} color='red' marginTop={5} marginLeft={5}>{errorMessages.lastname}</Text>
            }
            <ButtonLinear
              white
              title={profileMode ? 'Enviar' : 'Continuar'}
              children={
                <Image
                  source={iconNext}
                  style={styles.buttonChildren}
                />
              }
              disabled={enableButton}
              onPress={onSubmit}
              style={styles.buttonLinear}
            />
          </>}
      </Content>

      {visibleIdentificationTypesMenuOption ?
        <Modal
          visible={true}
          onRequestClose={closeIdentificationTypesMenuOption}
          transparent
          animationType={'fade'}>
          <ModalSelect
            onPressItem={item => {
              setIdentificationType(item);
              setMinDocLength(item.min);
              setMaxDocLength(item.max);
              if(item.id !== 1 && item.name !== 'DNI'){
                setSISAVerification(false);
              }
              setIdentification('');
            }}
            choices={identificationTypes}
            close={closeIdentificationTypesMenuOption}
          />
        </Modal>
        : null}
    </Container>
  );
};

export default BasicInformationMobile;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  buttonChildren: {
    ...Theme.icons,
    marginLeft: 8,
  },
  inputApp: {
    marginTop: 24,
  },
  name: {
    marginTop: scale(38),
  },
  homeAddress: {
    marginTop: 32,
  },
  buttonLinear: {
    marginTop: 52,
  },
  genders: {
    marginTop: 24,
    ...Theme.flexRow,
    ...Theme.center,
  },
  buttonIcon: {
    width: 50,
    height: 50,
    borderRadius: 50,
    backgroundColor: '#fafafa',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 0,
    marginTop: 0,
    paddingLeft: 16,
  },
  alingButtonSISA: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  }
});
