import React from "react";
import {
  View,
  StyleSheet,
  Image,
  Modal,
  TouchableOpacity,
  ImageBackground,
  ActivityIndicator,
} from "react-native";
import Text from "../../../components/Text";
import HeaderButton from "../../../components/HeaderButton";
import Theme from "../../../style/Theme";
import InputApp from "../../../components/InputApp";
import { Colors } from "../../../configs";
import TagItem from "../../../components/WorkProfile/TagItem";
import ButtonLinear from "../../../components/Buttons/ButtonLinear";
import locales_es from "../../../locales/es";
import { getStatusBarHeight } from "react-native-iphone-x-helper";
import ModalSelect from "../../../components/ModalSelect";
import { USER_STATUS_APPROVED } from "../../../models/constants";
import iconSearch from "../../../images/ic_search_normal@3x.png";
import iconNext from "../../../images/ic_next@3x.png";
import ImgLoadAndCrop from "../../../components/imgLoadAndCrop";
import Stepper from "../../BasicInformation/BasicInformationDesktop/stepper";
import { SOURCE_ICON } from "../../../images";
import useWorkProfilePage from "../shared/useWorkProfilePage";
import RequireIndicator from "../../../components/RequireIndicator";
import MedicalLicenses from "../../../components/MedicalLicenses.tsx";
import { width } from "../../../configs/Const";

interface WorkProfileProps {}

const WorkProfile = (props: WorkProfileProps) => {
  const {
    loadingLicense,
    stepNumber,
    theme,
    searchSpecialtiesResults,
    onSearchSpecialtiesChange,
    searchSpecialties,
    profession,
    setProfession,
    errors,
    onBlur,
    translations,
    prescriptionProfileAdditionalFields,
    userData,
    imgLoadAndCrop,
    uploadImage,
    identificationImage,
    setCropping,
    cropping,
    openCrop,
    onSubmit,
    visibleAdditionalFieldMenuOption,
    closeAdditionalFieldMenuOption,
    additionalFieldsOptions,
    medicalLicenseConfiguration,
    clinicData,
    medicalsLicenses,
    medicalLicenseSelected,
    handleMedicalLicenseChange,
    AddMedicalLicense,
    onSyncSisa,
    name,
  } = useWorkProfilePage();

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        {stepNumber ? (
          <Stepper
            step={stepNumber}
            hasTwoSteps={clinicData && !clinicData.paylink_enabled}
          />
        ) : (
          <Stepper
            step={2}
            hasTwoSteps={clinicData && !clinicData.paylink_enabled}
          />
        )}
        <View style={styles.headerWrapper}>
          <HeaderButton />
          <Text size={32} lineHeight={36} bold>
            {locales_es.workProfile.title}
          </Text>
          <Text
            size={16}
            lineHeight={18}
            marginTop={8}
            style={{ maxWidth: "100%" }}
          >
            {locales_es.workProfile.intro}
          </Text>
          <Text size={24} lineHeight={0} marginTop={30} bold>
            {name}
          </Text>
        </View>
        <View style={styles.pageContent}>
          <View style={styles.backgroundImageWrapper}>
            <Image source={SOURCE_ICON.rx} style={styles.backgroundImage} />
          </View>
          <View style={styles.formContainer}>
            <View style={styles.form}>
              <MedicalLicenses
                loadingLicense={loadingLicense}
                addMedicalLicense={AddMedicalLicense}
                onSelect={true}
                onSelectLicense={handleMedicalLicenseChange}
                medicalsLicenses={medicalsLicenses}
                onSetSelectedLicense={medicalLicenseSelected}
                onSyncSisa={onSyncSisa}
              />

              <View style={styles.cta}>
                <ButtonLinear
                  white
                  title={"Continuar"}
                  children={
                    <Image source={iconNext} style={styles.buttonChildren} />
                  }
                  onPress={onSubmit}
                  style={styles.buttonLinear}
                />
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default WorkProfile;

const styles = StyleSheet.create({
  buttonChildren: {
    ...Theme.icons,
    marginLeft: 8,
  },
  buttonLinear: {
    marginTop: 50,
  },
  imgContainer: { minWidth: 380, width: "70%", marginTop: 0 },
  avatarStyles: { width: 150, height: 150 },
  container: { flex: 1, minHeight: "100%" },
  content: {
    minHeight: "100%",
    paddingBottom: 24,
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: getStatusBarHeight(),
  },
  headerWrapper: {
    flexDirection: "column",
    alignItems: "flex-start",
    maxWidth: "100%",
    marginTop: 32,
  },
  pageContent: {
    flexDirection: "row-reverse",
    flexWrap: "wrap",
    justifyContent: "flex-end",
    marginTop: 32,
  },
  backgroundImageWrapper: {
    width: "40%",
    minWidth: 400,
    alignItems: "flex-start",
    marginBottom: 32,
  },
  backgroundImage: { width: 420, height: 380 },
  formContainer: { flex: 1, minWidth: 400 },
  form: { marginTop: 24, width: "80%" },
  prefixField: { width: 200 },
  prefixSelector: {
    padding: 0,
    marginTop: 0,
  },
  formRow: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: 16,
  },
  cta: { width: 200 },
  flexGrow: { flex: 1 },
  iconSearch: {
    ...Theme.icons,
    tintColor: Colors.DodgerBlue,
  },
  spec: {
    marginTop: 8,
    ...Theme.flexRow,
    flexWrap: "wrap",
  },
  specLanguage: {
    marginTop: 8,
    ...Theme.flexRow,
    flexWrap: "wrap",
    paddingBottom: 52,
  },
  imgUpload: {
    flex: 1,
    border: "2px dashed grey",
    borderRadius: 16,
    marginTop: 16,
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    flex: 1,
    justifyContent: "center",
  },
  licenseImage: {
    marginTop: 4,
    minHeight: 150,
    borderRadius: 8,
    backgroundColor: "#fff",
  },
  firstInfo: {
    paddingBottom: 24,
    borderBottomWidth: 1,
    borderColor: "#e8e8eb",
  },
});
