import React, { memo, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import Text from '../Text';
import { Colors } from '../../configs';
import ButtonLinear from '../Buttons/ButtonLinear';
import locales_es from '../../locales/es';
import Layout from '../Layout/Layout';
import InputApp from '../InputApp';

interface ModalInfoProps {
  title: string;
  action: any;
  onPressDefault: any;
  placeholder?: string;
  subTitle?: string;
  width?: string;
}

const ModalWithInputCuit = memo((props: ModalInfoProps) => {
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [isValid, setIsValid] = useState(false);
  const { title, action, onPressDefault, placeholder, subTitle, width } = props;

  const formatCuit = (value: string) => {
    let cleanValue = value.replace(/[^0-9]/g, '');
    if (cleanValue.length > 2) {
      cleanValue = cleanValue.slice(0, 2) + '-' + cleanValue.slice(2);
    }
    if (cleanValue.length > 11) {
      cleanValue = cleanValue.slice(0, 11) + '-' + cleanValue.slice(11);
    }
    return cleanValue;
  };

  const handleInputChange = (text: string) => {
    const formattedMessage = formatCuit(text);
    setMessage(formattedMessage);
    const validFormat = /^\d{2}-\d{8}-\d{1}$/.test(formattedMessage);
    if (!validFormat) {
      setError(locales_es.errorFormatCuit);
      setIsValid(false);
    } else {
      setError('');
      setIsValid(true);
    }
  };

  const handleAccept = () => {
    if (isValid) {
      action(message);
      onPressDefault();
      setError('');
    }
  };

  return (
    <View style={styles.container}>
      <Layout
        style={{
          borderRadius: 16,
          paddingHorizontal: 24,
          paddingVertical: 32,
          width: width ?? '90%',
          alignSelf: 'center',
        }}
      >
        <Text center size={17} lineHeight={20} bold>
          {title}
        </Text>
        {subTitle && subTitle.length > 0 && (
          <Text center size={13} lineHeight={22} marginTop={16}>
            {subTitle}
          </Text>
        )}
        <InputApp
          placeholder={placeholder ? placeholder : ''}
          value={message}
          onChangeText={handleInputChange}
          multiline={true}
          editable={true}
          maxLength={14}
        />
        {error && (
          <Text
            size={13}
            lineHeight={16}
            color="red"
            marginTop={5}
            marginLeft={5}
          >
            {error}
          </Text>
        )}
        <View style={styles.centerButton}>
          <ButtonLinear
            white
            title={locales_es.cancel}
            onPress={() => {
              onPressDefault();
              setError('');
            }}
            style={{ width: '95%' }}
            colors={[Colors.Red, Colors.RedNeonFuchsia]}
            styleButton={{ width: '45%' }}
          />
          <ButtonLinear
            white
            title={locales_es.accept}
            onPress={handleAccept}
            style={{ width: '95%' }}
            styleButton={{ width: '45%' }}
            disabled={!isValid}
          />
        </View>
      </Layout>
    </View>
  );
});

export default ModalWithInputCuit;

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.Black68,
    ...StyleSheet.absoluteFillObject,
    paddingHorizontal: 24,
    justifyContent: 'center',
    position: 'absolute',
    zIndex: 100,
  },
  centerButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 24,
    marginLeft: '2.5%',
    alignItems: 'center',
  },
});
