import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../configs";
import { api, auth, helpers } from "../../../import";
import { showModalInfo } from "../../../modules/ViewHelpers";
import CountryService from "../../../modules/CountryService";
import locales_es from "../../../locales/es";
import {
  EVENT_RELOAD_PRESCRIPTION_PROFILES,
  EVENT_UPLOAD_PROFILE_IMAGE,
} from "../../../constants/Events";
import { EventRegister } from "../../../modules/EventRegister";
import { USER_STATUS_APPROVED } from "../../../models/constants";

export interface IUserData {
  professional_prefix?: { name: string };
  name: string;
  lastname: string;
  country_id: number;
  email: string;
  email_verified_at: string;
  identification_type?: { name: string };
  identification?: string;
  idTributario?: string;
  prefix_cell_phone?: string;
  cell_phone?: string;
}

const useHomePage = () => {
  const history = useHistory();
  const countryService = new CountryService();
  const [load, setLoad] = useState(true);
  const [prescriptionProfiles, setPrescriptionProfiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState<IUserData | null>(null);
  const [incompleteProfile, setIncompleteProfile] = useState(false);
  const [validateUserModal, setValidateUserModal] = useState(false);
  const [services, setServices] = useState([]);
  const [medicinePermissionFlag, setMedicinePermissionFlag] = useState(false);
  const [userStatusEnabled, setUserStatusEnabled] = useState(false);

  const goToCompleteUserData = () => {
    history.replace(`/${Routes.SignUpSuccessful}`);
  };

  const goToCompleteProfile = (profile: any) => {
    if (profile && (!profile.address || !profile.medical_licenses)) {
      history.replace(`/${Routes.WorkProfile}`, {
        prescriptionProfileId: profile.id,
        medicalLicense: {
          medical_license: profile.medical_licenses
            ? profile.medical_licenses
            : null,
        },
        stepTitle: locales_es.stepOneOfTwo,
        stepNumber: 1,
        hasSignup: true,
      });
    }

    if (
      profile &&
      (!profile.medical_licenses ||
        !profile.medical_licenses.profession ||
        !profile.medical_licenses.medical_licenses_type ||
        !profile.medical_licenses.medical_licenses_jurisdiction ||
        !JSON.parse(profile.medical_licenses.specialities).length)
    ) {
      history.replace(`/${Routes.AddMedicalLicense}`, {
        edit: true,
        medicalLicense: {
          medical_license: profile.medical_licenses,
        },
      });
    }
  };

  const onCompleteYourProfile = () => {
    if (typeof incompleteProfile === "boolean") {
      goToCompleteUserData();
    } else {
      goToCompleteProfile(incompleteProfile);
    }
  };

  const loadPrescriptionProfiles = (dontRedirect: boolean) => {
    api
      .getMyPrescriptionProfiles()
      .then((res: any) => {
        setPrescriptionProfiles(res.data);

        if (res.data.length) {
          let failed = 0;
          res.data.map((profile: any) => {
            if (helpers.profileValidator(profile) && !failed) {
              console.log(profile);
              failed++;
              setIncompleteProfile(profile);
              !dontRedirect && goToCompleteProfile(profile);
            }
          });
        } else {
          !dontRedirect && goToCompleteUserData();
          setIncompleteProfile(true);
        }
        setLoad(false);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const loadMyServices = () => {
    api
      .getMyServices()
      .then((res: any) => {
        if (res && res.data && res.data.length) {
          setServices(res.data);
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const loadProfileInfo = () => {
    auth
      .getRemoteUserData()
      .then((res: any) => {
        const user = res.data.user;
        setUserStatusEnabled(user.status === USER_STATUS_APPROVED);
        setUserData(user);
        setMedicinePermissionFlag(
          res.data.permission && res.data.user.status === USER_STATUS_APPROVED
        );

        // TODO remove this in the future, when everybody have setted up their country locally
        countryService.setData(res.data.user.country);
        if (!user.name || !user.lastname || !user.professional_prefix_id) {
          goToCompleteUserData();
          setIncompleteProfile(true);
          return;
        }

        if (load) {
          loadPrescriptionProfiles(false);
          loadMyServices();
        }
      })
      .catch((err: any) => {
        helpers.processError(err);
        setUserData(null);
      });
  };

  useEffect(() => {
    if (load && userData && userData.country_id) {
      // checkUserValidation();
    }
  }, [userData]);

  const checkUserValidation = () => {
    api
      .getCountry(userData?.country_id)
      .then((res: any) => {
        if (
          res.data &&
          res.data.email_confirmation_enabled &&
          !userData?.email_verified_at
        ) {
          setValidateUserModal(true);
        }
      })
      .catch((err: any) => {
        helpers.processError(err);
      });
  };

  useEffect(() => {
    loadProfileInfo();
  }, [load]);

  useEffect(() => {
    const listener = EventRegister.addEventListener(
      EVENT_UPLOAD_PROFILE_IMAGE,
      () => {
        loadProfileInfo();
      }
    );

    const listenerPrescriptionProfiles = EventRegister.addEventListener(
      EVENT_RELOAD_PRESCRIPTION_PROFILES,
      () => {
        loadPrescriptionProfiles(true);
      }
    );

    return function cleanup() {
      EventRegister.removeEventListener(listener);
      EventRegister.removeEventListener(listenerPrescriptionProfiles);
    };
  });

  const onPressAccountResend = () => {
    setValidateUserModal(false);
    setLoading(true);
    api
      .resendEmailVerification(userData?.email)
      .then((res: any) => {
        showModalInfo(locales_es.successModal.title, res.message);
        setLoading(false);
      })
      .catch((err: any) => {
        helpers.processError(err);
        setLoading(false);
        setValidateUserModal(true);
      });
  };

  return {
    userData,
    incompleteProfile,
    services,
    loading,
    onPressAccountResend,
    onCompleteYourProfile,
    load,
    validateUserModal,
    setValidateUserModal,
    medicinePermissionFlag,
    userStatusEnabled,
  };
};

export default useHomePage;
