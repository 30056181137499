import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Alert,
} from 'react-native';
import locales_es from "../../locales/es";
import Helpers from "../../modules/helpers";
import APIService from "../../modules/apiService";
// import SignatureCapture from 'react-native-signature-capture';
import SignatureCanvas from 'react-signature-canvas'
import ButtonLinear from '../../components/Buttons/ButtonLinear';
import ButtonBorder from '../../components/Buttons/ButtonBorder';
import ButtonText from '../../components/Buttons/ButtonText';
import {EVENT_LOAD_SIGNATURE, EVENT_SHOW_MODAL_INFO} from '../../constants/Events';
import FullScreenLoading from '../../components/fullscreenLoading';
import {Colors} from '../../configs';
import {EventRegister} from "../../modules/EventRegister";

export default class AddSignature extends Component {

  constructor(props) {
    super(props);
    console.log('AddSignature', this.props);
    this.state = {
      spinner: false,
      prescriptionProfileId: this.props.prescriptionProfileId
    };

    this.api = new APIService();
    this.helpers = new Helpers();
  }

  setSpinner(bool) {
    this.setState({
      spinner: bool
    });
  }

  resetSign() {
    this.refs["sign"].clear ? this.refs["sign"].clear() : this.refs["sign"].resetImage();
  }

  goBack() {
    this.props.onFinish ? this.props.onFinish() : this.props.navigation.goBack();
  }

  saveSign(result) {
    this.setSpinner(true);
    const base64 = this.refs["sign"].toDataURL();
    if (this.props.prescriptionProfileId){

      this.api.updateSignatureImage(this.state.prescriptionProfileId, base64).then(res => {
        // Alert.alert(locales_es.successModal.title, res.message);
        EventRegister.emit(EVENT_LOAD_SIGNATURE);
        this.setSpinner(false);
        this.goBack();
      }).catch(err => {
        EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
          title: locales_es.errorModal.title,
          message: this.helpers.getErrorMsg(err),
        })
        this.setSpinner(false);
      })
    }else {

      EventRegister.emit(EVENT_LOAD_SIGNATURE, base64);
      this.setSpinner(false);
      this.goBack();
    }

  }

  render() {

    const onCancel = () => {
      this.goBack();
    }

    const width = this.props.width || window.innerWidth;
    const height = this.props.height || (window.innerHeight / 2);
    return (
      <View style={{ flex: 1}}>
        {this.state.spinner && <FullScreenLoading/>}
        <View style={{width, height, margin: 'auto', backgroundColor: '#fff', borderColor: '#000', borderWidth: 1, borderRadius: 8, marginTop: 50}}>
        <SignatureCanvas ref="sign" penColor={Colors.Blue}
                         throttle={0}
        canvasProps={{width, height, className: 'sigCanvas'}} />
        </View>
        {/*<SignatureCapture
            style={[{flex:0.5}, styles.signature]}
            ref="sign"
            onSaveEvent={(result) => this._onSaveEvent(result)}
            // onDragEvent={() => this._onDragEvent()}
            saveImageFileInExtStorage={false}
            showNativeButtons={false}
            showTitleLabel={false}
            backgroundColor="transparent"
            strokeColor={Colors.Blue}
            viewMode={"portrait"}/>*/}

        <View style={{ 
          flex: 0.5,
          // flexDirection: 'row',
          // justifyContent: 'space-around',
          marginVertical: 30,
        }}>

          <View style={{
            flex: 1,
            width: '40%',
            margin: 'auto',
            marginTop: 10
          }}>
            <ButtonLinear
                white
                title={locales_es.save}
                style={styles.button}
                onPress={() => { this.saveSign() } }
            />
          </View>

          <View style={{
            flex: 1,
            width: '40%',
            margin: 'auto',
            marginTop: 10
          }}>
            <ButtonBorder
                title={locales_es.clean}
                style={styles.button}
                onPress={() => { this.resetSign() }}
            />
          </View>

          <View style={{
            flex: 1,
            width: '40%',
            margin: 'auto',
            marginTop: 10
          }}>
            <ButtonText
                title={locales_es.cancel}
                style={styles.buttonText}
                onPress={onCancel}
                titleColor='white'
            />
            
          </View>
        </View>
      </View>
    );
  }

}

const styles = StyleSheet.create({
  signature: {
    // flex: 1,
    borderColor: '#000033',
    borderWidth: 1,
    height: '80%',
    width: '100%'
  },
  buttonStyle: {
    flex: 1, justifyContent: "center", alignItems: "center", height: 50,
    backgroundColor: "#eeeeee",
    margin: 10
  },
  button: {
    marginVertical: 5,
    marginHorizontal: 20,
  },
  buttonText: {
    borderWidth: 0,
    marginVertical: 5,
    marginHorizontal: 20,
    backgroundColor: Colors.Tomato,
    color: 'white',
    height: 50,
  }
});
