import additionalInformation from "../images/ic_additional_information@3x.png";
import ar from "./../images/country/img_flag_ar@3x.png";
import arrowRight from "../images/ic_arrow_right@3x.png";
import back from "../images/ic_back@3x.png";
import calendar from "../images/ic_calendar@3x.png";
import checkMark from "../images/ic_checkmark@3x.png";
import condition from "../images/ic_condition.png";
import doctor from "../images/ic_doctor@3x.png";
import ec from "./../images/country/img_flag_ec@3x.png";
import edit from "../images/ic_edit@3x.png";
import exp from "../images/ic_exp@3x.png";
import help from "../images/ic_help_white@3x.png";
import home from "../images/ic_home_active@3x.png";
import hospitalBed from "../images/ic_hospital_bed@3x.png";
import ic_rate_full from "../images/ic_rate_full@3x.png";
import medication from "../images/ic_medication@3x.png";
import pencil from "../images/ic_pencil@3x.png";
import plus from "../images/ic_plus@3x.png";
import py from "./../images/country/img_flag_py@3x.png";
import qrPrint from "../images/ic_qr_print.png";
import qrShow from "../images/ic_qr_show.png";
import resetSearch from "../images/ic_reset_search@3x.png";
import setting from "../images/ic_setting@3x.png";
import term from "../images/ic_term@3x.png";
import whitePlus from "../images/ic_white_plus@3x.png";
import share from "../images/ic_share@3x.png";
import prescriptions from "../images/Home/img_other_prescriptions.png";
import account from "../images/ic_account_normal@3x.png";
import sideEffect from "../images/ic_side_effect@3x.png";
import eyeOn from "../images/ic_eye_on@3x.png";
import add from "../images/ic_add_16@3x.png";
import drug from "../images/ic_add_drug@3x.png";
import rx from "../images/rx.png";
import attach from "../images/ic_attach@3x.png";
import photoLibrary from "../images/ic_photo_library@3x.png";
import trashIcon from "../images/ic_trash@3x.png";
import sync from "../images/ic_sync@3x.png";
import successGreen from "../images/icon-success-circle-green-tick.png";
import success from "../images/img_success_1.png";
import hide from "../images/ic_hide.png";
import logo from "../images/logo.png";
import invite from "../images/invite.png";
import list from "../images/list.png";
import logout from "../images/logout.png";
import note from "../images/note.png";
import patient from "../images/patient.png";
import setting2 from "../images/setting_2.png";
import table from "../images/table-list.png";
import about from "../images/about.png";

export const SOURCE_ICON: any = {
  about,
  table,
  setting2,
  patient,
  note,
  logout,
  list,
  invite,
  logo,
  hide,
  successGreen,
  success,
  account,
  add,
  additionalInformation,
  ar,
  arrowRight,
  attach,
  back,
  calendar,
  checkMark,
  condition,
  doctor,
  drug,
  eyeOn,
  ec,
  edit,
  exp,
  help,
  home,
  hospitalBed,
  ic_rate_full,
  medication,
  pencil,
  plus,
  py,
  qrPrint,
  qrShow,
  resetSearch,
  setting,
  term,
  whitePlus,
  share,
  sideEffect,
  prescriptions,
  rx,
  photoLibrary,
  trashIcon,
  sync,
};
