import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import InputApp from "../../../components/InputApp";
import ListItem from "../../../components/ListItem";
import { Routes } from "../../../configs";
import { useTheme } from "../../../configs/Theme";
import useModalWithKeyboard from "../../../hooks/useModalWithKeyboard";
import { api, auth, helpers } from "../../../import";
import locales_es from "../../../locales/es";
import { showModalInfo } from "../../../modules/ViewHelpers";
import {
  validateEmptyArrays,
  validateEmptyFields,
} from "../../../utils/validation/forms";

let nativeEventTestSearchSpecialties = "";

const useAddMedicalLicense = () => {
  const history = useHistory();
  const params: any = history.location.state;
  let firstProfile: any =
    params && params.firstProfile ? params.firstProfile : null;
  const [profession, setProfession] = useState<any>("");
  const [medicalLicenseType, setMedicalLicenseType] = useState<any>("");
  const [licenseImage, setLicenseImage] = useState<any>("");
  const [cropping, setCropping] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>([]);
  const { theme } = useTheme();
  const [searchSpecialties, setSearchSpecialties] = useState<any>("");
  const [searchSpecialtiesResults, setSearchSpecialtiesResults] = useState<
    any[] | null | undefined
  >([]);
  const [specialties, setSpecialties] = useState<any>([]);
  const [medicalLicenseValue, setMedicalLicenseValue] = useState<any>({});
  const [additionalFieldsOptions, setAdditionalFieldsOptions] = useState<any>(
    []
  );
  const [medicalLicenseConfiguration, setMedicalLicenseConfiguration] =
    useState<any>([]);
  const [
    prescriptionProfileAdditionalFields,
    setPrescriptionProfileAdditionalFields,
  ] = useState<any>([]);
  const [medicalLicenseId, setMedicalLicenseId] = useState(null);
  const [edit, setEdit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [sisa, setSisa] = useState<boolean>(false);
  const [editSpecialities, setEditSpecialities] = useState<boolean>(true);
  const [editProfession, setEditProfession] = useState<boolean>(true);
  const imgLoadAndCrop: any = useRef();
  const [openCropSisa, setOpenCropSisa] = useState<boolean>(false);
  const [auxMedicalLicenseSelected, setAuxMedicalLicenseSelected] =
    useState<any>("");

  useEffect(() => {
    console.log(params);

    if (params && params.medicalLicense) {
      if (params && params.medicalLicense.medical_license.profession) {
        setProfession(params.medicalLicense.medical_license.profession);
      }
      if (params && params.medicalLicense.medical_license.medicalLicenseType) {
        setMedicalLicenseType(
          params.medicalLicense.medical_license.medicalLicenseType
        );
      }

      if (params && params.medicalLicense.medical_license.specialities) {
        const specialtiesArray = JSON.parse(
          params.medicalLicense.medical_license.specialities
        );
        if (specialtiesArray.length > 0) {
          setSpecialties(specialtiesArray);
        }
      }
      if (params && params.medicalLicense.medical_license) {
        setMedicalLicenseValue(params.medicalLicense.medical_license);
      }
      if (params && params.medicalLicense.edit) {
        setEdit(params.medicalLicense.edit);
      }
      if (params && params.edit) {
        setEdit(params.edit);
      }
      if (params && params.medicalLicense.medical_license.id) {
        setMedicalLicenseId(params.medicalLicense.medical_license.id);
      }
      if (params && params.medicalLicense.medical_license.source == "sisa") {
        setSisa(true);
      }
      if (
        params &&
        params.medicalLicense.medical_license.source == "sisa" &&
        JSON.parse(params.medicalLicense.medical_license.specialities).length >
          0
      ) {
        setEditSpecialities(false);
      }
      if (
        params &&
        params.medicalLicense.medical_license.source === "sisa" &&
        params.medicalLicense.medical_license.profession !== ""
      ) {
        setEditProfession(false);
      }
    }
    if (params && params.auxMedicalLicenseSelected) {
      setAuxMedicalLicenseSelected(params.auxMedicalLicenseSelected);
    }
  }, [params]);

  const onBlur = (inputStr: string) => {
    setErrors(errors.filter((item: any) => item !== inputStr));
  };
  const openCrop = () => {
    if (!sisa && !openCropSisa) {
      imgLoadAndCrop.current.selectImageFile();
    }
  };

  useEffect(() => {
    auth
      .getRemoteUserData()
      .then((res: any) => {
        if (res && res.data && res.data.user) {
          if (params && params.medicalLicense && params.medicalLicense.id) {
            api
              .getMedicalLicense(params.medicalLicense.medical_license_id)
              .then((res) => {
                if (
                  res.data.full_medical_license_image !== null &&
                  res.data.full_medical_license_image !== "" &&
                  res.data.full_medical_license_image.length &&
                  res.data.full_medical_license_image !== undefined
                ) {
                  setLicenseImage({ uri: res.data.full_medical_license_image });
                } else {
                }
              })
              .catch((err) => {
                showModalInfo(
                  locales_es.infoModal.title,
                  helpers.getErrorMsg(err)
                );
              });
          }
          setOpenCropSisa(false);
          api
            .getCountries()
            .then(async (res3: any) => {
              setPrescriptionProfileAdditionalFields(
                res3.data.filter(
                  (country: any) => country.id === res.data.user.country_id
                )[0].prescription_profile_additional_fields.inputs
              );

              const additionalFieldsOptionsRes = await api.getConfigurations();
              setMedicalLicenseConfiguration(
                additionalFieldsOptionsRes.configurations
              );
            })
            .catch((err3: any) => {
              showModalInfo(
                locales_es.infoModal.title,
                helpers.getErrorMsg(err3)
              );
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onSubmit = () => {
    if (sisa) {
      const errors = validateEmptyFields({
        profession,
      });
    } else {
      const errors = validateEmptyFields({
        profession,
        licenseImage,
      });
    }

    if (!validateEmptyArrays(specialties)) {
      errors.push("searchSpecialties");
    }

    medicalLicenseConfiguration.map((field: any) => {
      if (!medicalLicenseValue[field.code]) {
        errors.push(field.code);
      }
    });

    if (errors.length) {
      setErrors(errors);
      showModalInfo(locales_es.infoModal.title, locales_es.infoModal.checkData);
    } else {
      setLoading(true);
      const objData = {
        profession: profession,
        specialities: specialties,
        medical_license_image: licenseImage ? licenseImage.uri : null,
        source: sisa ? "sisa" : "manual",
        additional_fields: {
          inputs: prescriptionProfileAdditionalFields,
        },
        medical_license: medicalLicenseValue,
        optional_fields: [],
      };
      if (edit == true) {
        api
          .putMedicalLicense(medicalLicenseId, objData)
          .then((res) => {
            setLoading(false);
            showModalInfo(
              locales_es.infoModal.title,
              "Licencia médica actualizada correctamente"
            );
            history.push(`/${Routes.Configuration}`);
          })
          .catch((err) => {
            setLoading(false);
            helpers.getErrorMsg(err) ==
            "Ya existe una matrícula con el mismo número. Por favor, añade nuevas matrículas"
              ? showModalInfo("¡Matrícula Duplicada!", helpers.getErrorMsg(err))
              : showModalInfo(
                  locales_es.infoModal.title,
                  helpers.getErrorMsg(err)
                );
          });
      } else {
        api
          .postMedicalLicense(objData)
          .then((res) => {
            setLoading(false);
            showModalInfo(
              locales_es.infoModal.title,
              "Licencia médica agregada correctamente"
            );
            if (params && params.from === "/WorkProfile") {
              history.push(`/${Routes.WorkProfile}`, {
                stepTitle: locales_es.stepOneOfThree,
                stepNumber: firstProfile ? 2 : 1,
                firstProfile,
                auxMedicalLicenseSelected,
              });
            } else {
              history.push(`/${Routes.Configuration}`);
            }
          })
          .catch((err) => {
            setLoading(false);
            helpers.getErrorMsg(err) ==
            "Ya existe una matrícula con el mismo número. Por favor, añade nuevas matrículas"
              ? showModalInfo("¡Matrícula Duplicada!", helpers.getErrorMsg(err))
              : showModalInfo(
                  locales_es.infoModal.title,
                  helpers.getErrorMsg(err)
                );
          });
      }
    }
  };

  useEffect(() => {
    if (searchSpecialties && searchSpecialties.length > 2) {
      setSearchSpecialtiesResults(null);
      const objData = {
        q: searchSpecialties,
      };
      setTimeout(() => {
        if (
          searchSpecialties.length === nativeEventTestSearchSpecialties.length
        ) {
          api
            .searchSpecialties(objData)
            .then((res) => {
              setSearchSpecialtiesResults(res.data);
            })
            .catch((err) => {
              showModalInfo(
                locales_es.infoModal.title,
                helpers.getErrorMsg(err)
              );
            });
        } else {
          setSearchSpecialtiesResults([]);
        }
      }, 400); // 800
    }
  }, [searchSpecialties]);

  const onSearchSpecialtiesChange = (e: any) => {
    // e.persist(); // https://reactjs.org/docs/legacy-event-pooling.html
    nativeEventTestSearchSpecialties = e.nativeEvent.text;
    setSearchSpecialties(e.nativeEvent.text);
  };

  const onPressSearchResult = (name: any) => {
    const duplicated = specialties.filter((item: any) => item === name);
    if (duplicated.length) {
      showModalInfo(
        locales_es.infoModal.title,
        "Ya has agregado esta especialidad"
      );
      return;
    }

    if (name) {
      setSpecialties([...specialties, name]);
      setSearchSpecialtiesResults([]);
      setSearchSpecialties("");
    }
  };

  const onPressSpecialtyX = (index: any) => {
    if (!editSpecialities) {
      return;
    }

    const items = JSON.parse(JSON.stringify(specialties));
    items.splice(index, 1);
    setSpecialties(items);
  };

  const renderListItem = (item: any) => {
    return (
      <ListItem
        key={item}
        item={{
          id: new Date().getTime(),
          name: item,
        }}
        onPress={() => onPressSearchResult(item)}
      />
    );
  };

  const onChangeInput = (text: any, additionalField: any) => {
    const newFilters = JSON.parse(
      JSON.stringify(prescriptionProfileAdditionalFields)
    );
    if (newFilters && newFilters.length) {
      const newFilter = medicalLicenseConfiguration.filter(
        (field: any) => field.code === additionalField.code
      )[0];

      newFilters.filter(
        (field: any) =>
          field.name ===
          `professional_registration_${
            additionalField.code !== "identification"
              ? additionalField.code
              : "number"
          }`
      )[0].value =
        newFilter.input_type !== "text"
          ? newFilter.code === "type"
            ? additionalField.text
            : additionalField.name
          : text;

      medicalLicenseValue[additionalField.code] =
        newFilter.input_type !== "text" ? additionalField.id : text;
      if (additionalField.id === 1 && additionalField.code === "type") {
        medicalLicenseValue.jurisdiction = 3;
      }
      if (additionalField.id === 2 && additionalField.code === "type") {
        medicalLicenseValue.jurisdiction = "";
      }

      setPrescriptionProfileAdditionalFields(newFilters);
    }
  };

  const getTextFromId = (options: any, id: any) => {
    if (!options) return "";
    for (const option of options) {
      if (option.id === id) {
        return option.text || option.name;
      }
    }
    return "";
  };

  const handleOnPressInputSelect = (additionalField: any) => {
    let additionalFieldOptions = additionalField.options;
    if (additionalField.id === 4 && medicalLicenseValue.type === 2) {
      additionalFieldOptions = additionalField.options.filter(
        (option: any) => option.id !== 1 && option.id !== 3
      );
    }

    setAdditionalFieldsOptions(additionalFieldOptions);
  };

  const renderAdditionalField = (additionalField: any) => {
    if (additionalField.input_type === "select") {
      let value = getTextFromId(
        additionalField.options,
        medicalLicenseValue[additionalField.code]
      );
      if (additionalField.id === 4 && medicalLicenseValue.type === 2) {
        value = getTextFromId(
          additionalField.options.filter(
            (option: any) => option.id !== 1 && option.id !== 3
          ),
          medicalLicenseValue[additionalField.code]
        );
      }

      return (
        <>
          {medicalLicenseValue.type === 1 && additionalField.id === 4 ? null : (
            <InputApp
              key={additionalField.id}
              title={additionalField.label}
              marginTop={24}
              value={value}
              editable={false}
              onPress={() => {
                if (!sisa) {
                  handleOnPressInputSelect(additionalField);
                  openAdditionalFieldMenuOption();
                }
              }}
              borderColor={
                errors.includes(additionalField.code)
                  ? theme.lineErrorColor
                  : theme.innearColor
              }
              onBlur={() => onBlur(additionalField.name)}
              showRequired={true}
            />
          )}
        </>
      );
    }
    if (additionalField.input_type === "text") {
      return (
        <InputApp
          key={additionalField.id}
          placeholder={additionalField.label}
          title={additionalField.label}
          value={medicalLicenseValue[additionalField.code]}
          autoCapitalize="words"
          marginTop={24}
          borderColor={
            errors.includes(additionalField.code)
              ? theme.lineErrorColor
              : theme.innearColor
          }
          onChangeText={(text: any) => {
            onChangeInput(text, additionalField);
          }}
          onBlur={() => onBlur(additionalField.name)}
          showRequired={true}
          editable={!sisa}
        />
      );
    }
    return null;
  };

  const {
    visible: visibleAdditionalFieldMenuOption,
    open: openAdditionalFieldMenuOption,
    close: closeAdditionalFieldMenuOption,
  } = useModalWithKeyboard(false);

  const uploadImage = (image: any) => {
    if (edit) {
      api
        .updateMedicalLicenseImage(medicalLicenseId, image)
        .then((res) => {
          setLicenseImage({ uri: image });
        })
        .catch((err) => {
          showModalInfo(locales_es.infoModal.title, helpers.getErrorMsg(err));
        });
    } else {
      setCropping(false);
      setLicenseImage({
        uri: image,
      });
    }
    // setLoadingText(locales_es.uploadingImage);
    // uploadIdentificationImage(image)
    //   .then(() => {
    //     setLoading(false);
    //     setLoadingText("");
    //     setIdentificationImage({
    //       uri: image,
    //     });
    //   })
    //   .catch(() => {
    //     setLoading(false);
    //     setLoadingText("");
    //   });
  };

  return {
    onSubmit,
    sisa,
    imgLoadAndCrop,
    openCrop,
    visibleAdditionalFieldMenuOption,
    closeAdditionalFieldMenuOption,
    renderListItem,
    renderAdditionalField,
    medicalLicenseConfiguration,
    specialties,
    searchSpecialtiesResults,
    searchSpecialties,
    setSearchSpecialties,
    setSearchSpecialtiesResults,
    cropping,
    errors,
    theme,
    onPressSearchResult,
    onPressSpecialtyX,
    onSearchSpecialtiesChange,
    onBlur,
    setErrors,
    setCropping,
    profession,
    setProfession,
    medicalLicenseType,
    setMedicalLicenseType,
    licenseImage,
    setLicenseImage,
    setMedicalLicenseConfiguration,
    onChangeInput,
    additionalFieldsOptions,
    uploadImage,
    editSpecialities,
    editProfession,
  };
};

export default useAddMedicalLicense;
